import './App.css';
import React, { useState, useContext } from 'react';

import { useMediaQuery } from 'react-responsive'

import logEvent from './KeenHelper'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Dialog from '@mui/material/Dialog';

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const BETRIVERS_KEY = 'BR'
const BETRIVERS_LABEL = 'BetRivers'

const CAESARS_KEY = 'CS'
const CAESARS_LABEL = 'Caesars'

const DRAFT_KINGS_KEY = 'DK'
const DRAFT_KINGS_LABEL = 'Draft Kings'

const EAGLE_KEY = 'EG'
const EAGLE_LABEL = 'Eagle'

const FANDUEL_KEY = 'FD'
const FANDUEL_LABEL = 'FanDuel'

const GOLDEN_NUGGET_KEY = 'GN'
const GOLDEN_NUGGET_LABEL = 'Golden Nugget'

const GUN_LAKE_KEY = 'GL'
const GUN_LAKE_LABEL = 'Gun Lake'

const SPORTS_ILLUSTRATED_LABEL = 'Sports Illustrated'
const SPORTS_ILLUSTRATED_KEY = 'SI'

let SITE_LABEL_BY_KEY = {}
SITE_LABEL_BY_KEY[BETRIVERS_KEY] = BETRIVERS_LABEL
SITE_LABEL_BY_KEY[CAESARS_KEY] = CAESARS_LABEL
SITE_LABEL_BY_KEY[DRAFT_KINGS_KEY] = DRAFT_KINGS_LABEL
SITE_LABEL_BY_KEY[EAGLE_KEY] = EAGLE_LABEL
SITE_LABEL_BY_KEY[FANDUEL_KEY] = FANDUEL_LABEL
SITE_LABEL_BY_KEY[GOLDEN_NUGGET_KEY] = GOLDEN_NUGGET_LABEL
SITE_LABEL_BY_KEY[GUN_LAKE_KEY] = GUN_LAKE_LABEL
SITE_LABEL_BY_KEY[SPORTS_ILLUSTRATED_KEY] = SPORTS_ILLUSTRATED_LABEL

const BONUS_ACTIVATED_KEY = 'BA'
const BONUS_DEACTIVATED_KEY = 'BD'

let MODE_LABEL_BY_KEY = {}
MODE_LABEL_BY_KEY[BONUS_ACTIVATED_KEY] = 'Load'
MODE_LABEL_BY_KEY[BONUS_DEACTIVATED_KEY] = 'Collect'

const THREE_PLAY_KEY = '3P'
const FIVE_PLAY_KEY = '5P'
const TEN_PLAY_KEY = '10P'

const JACKS_OR_BETTER_KEY = 74
const JACKS_OR_BETTER_LABEL = 'Jacks or Better'

const BONUS_POKER_KEY = 37
const BONUS_POKER_LABEL = 'Bonus Poker'

const BONUS_POKER_DELUXE_KEY = 40
const BONUS_POKER_DELUXE_LABEL = 'Bonus Poker Deluxe'

const DOUBLE_BONUS_POKER_KEY = 51
const DOUBLE_BONUS_POKER_LABEL = 'Double Bonus'

const DOUBLE_DOUBLE_BONUS_POKER_KEY = 57
const DOUBLE_DOUBLE_BONUS_POKER_LABEL = 'Double Double Bonus'

const TRIPLE_DOUBLE_BONUS_POKER_KEY = 120
const TRIPLE_DOUBLE_BONUS_POKER_LABEL = 'Triple Double Bonus'

const DEUCES_WILD_POKER_KEY = 44
const DEUCES_WILD_POKER_LABEL = 'Deuces Wild'

const DEUCES_WILD_BONUS_POKER_KEY = 34
const DEUCES_WILD_BONUS_POKER_LABEL = 'Deuces Wild Bonus'

const JOKER_POKER_KEY = 79
const JOKER_POKER_LABEL = 'Joker Poker'

let GAME_LABEL_BY_KEY = {}
GAME_LABEL_BY_KEY[JACKS_OR_BETTER_KEY] = JACKS_OR_BETTER_LABEL
GAME_LABEL_BY_KEY[BONUS_POKER_KEY] = BONUS_POKER_LABEL
GAME_LABEL_BY_KEY[BONUS_POKER_DELUXE_KEY] = BONUS_POKER_DELUXE_LABEL
GAME_LABEL_BY_KEY[DOUBLE_BONUS_POKER_KEY] = DOUBLE_BONUS_POKER_LABEL
GAME_LABEL_BY_KEY[DOUBLE_DOUBLE_BONUS_POKER_KEY] = DOUBLE_DOUBLE_BONUS_POKER_LABEL
GAME_LABEL_BY_KEY[TRIPLE_DOUBLE_BONUS_POKER_KEY] = TRIPLE_DOUBLE_BONUS_POKER_LABEL
GAME_LABEL_BY_KEY[DEUCES_WILD_POKER_KEY] = DEUCES_WILD_POKER_LABEL
GAME_LABEL_BY_KEY[DEUCES_WILD_BONUS_POKER_KEY] = DEUCES_WILD_BONUS_POKER_LABEL
GAME_LABEL_BY_KEY[JOKER_POKER_KEY] = JOKER_POKER_LABEL

const BLANK_CARD = 54

//Sample custom tool tip.. cant try later
const HoldToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: white;
    background-color: #15C01B;
`);

function App({logout, user}) {

  const [selectedSite, setSelectedSite] = useState(GOLDEN_NUGGET_KEY);
  const [selectedMode, setSelectedMode] = useState(BONUS_ACTIVATED_KEY);
  const [selectedNumHands, setSelectedNumHands] = useState(TEN_PLAY_KEY);
  const [selectedGame, setSelectedGame] = useState(JACKS_OR_BETTER_KEY);
  const [selectedCards, setSelectedCards] = useState([BLANK_CARD, BLANK_CARD, BLANK_CARD, BLANK_CARD, BLANK_CARD])
  const [holdCards, setHoldCards] = useState([])
  const [allHoldOptions, setAllHoldOptions] = useState([])
  const [ev, setEV] = useState(null)
  const [open, setOpenEVDialog] = useState(false)

  const isMobile = useMediaQuery({
    query: '(max-width: 650px)'
  })

  function analyze() {

    const url = new URL(window.location.href + "/analyze");

    url.searchParams.append('game', selectedGame);
    url.searchParams.append('mode', selectedMode);
    url.searchParams.append('play', selectedNumHands);
    url.searchParams.append('site', selectedSite);
    url.searchParams.append('c0', selectedCards[0]);
    url.searchParams.append('c1', selectedCards[1]);
    url.searchParams.append('c2', selectedCards[2]);
    url.searchParams.append('c3', selectedCards[3]);
    url.searchParams.append('c4', selectedCards[4]);

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url.href);
    xhr.onload = function() {
      if (xhr.status === 200) {
        let results = JSON.parse(xhr.responseText)

        let eventData = {
          email: user.email,
          displayName: user.displayName,
          uid: user.uid,
          url: url.href,
          cards: selectedCards,
          game: GAME_LABEL_BY_KEY[selectedGame],
          mode: MODE_LABEL_BY_KEY[selectedMode],
          play: selectedNumHands,
          site: SITE_LABEL_BY_KEY[selectedSite],
          holds: results[0].holds,
          ev: parseFloat(results[0].ev).toPrecision(3),
        }

        logEvent('handAnalyzed', eventData)

        setHoldCards(results[0].holds)
        setEV(parseFloat(results[0].ev).toPrecision(3))
        setAllHoldOptions(results)
      }
    };
    xhr.send();
  }

  function openEV() {
    setOpenEVDialog(true)
  }

  function closeEv() {
    setOpenEVDialog(false)
  }

  function clearResults() {
    setHoldCards([])
    setAllHoldOptions([])
    setEV(null)
  }

  function onSiteClicked(siteKey) {
    setSelectedSite(siteKey)
    clearResults()
  }

  function onModeClicked(modeKey) {
    setSelectedMode(modeKey)
    clearResults()
  }

  function onNumHandsClicked(handsKey) {
    setSelectedNumHands(handsKey)
    clearResults()
  }

  function onGameClicked(gameKey) {
    setSelectedGame(gameKey)
    clearResults()
  }

  function onCardClicked(cardNumber) {
    let newSelectedCards = [...selectedCards]
    let index, firstBlankCardIndex;

    if ((index = selectedCards.indexOf(cardNumber)) != -1)
    {
      newSelectedCards[index] = BLANK_CARD
    }
    else if((firstBlankCardIndex = selectedCards.indexOf(BLANK_CARD)) != -1 )
    {
      newSelectedCards[firstBlankCardIndex] = cardNumber
    }
    else
    {
      newSelectedCards = [cardNumber, BLANK_CARD, BLANK_CARD, BLANK_CARD, BLANK_CARD] //Clear what's in there
    }

    setSelectedCards(newSelectedCards)
    clearResults()
  }

  function onBigCardClicked(index) {
    let newSelectedCards = [...selectedCards]
    newSelectedCards[index] = BLANK_CARD
    setSelectedCards(newSelectedCards)
    clearResults()
  }

  function clear() {
    setSelectedCards([BLANK_CARD, BLANK_CARD, BLANK_CARD, BLANK_CARD, BLANK_CARD])
    clearResults()
  }

  let cards = []
  let cardMargin = 2
  let blockMargin = 15
  let logoHeight = '50px'
  let appPadding = '30px'

  if(isMobile)
  {
    cardMargin = 0
    logoHeight = '40px'
    appPadding = '15px'
  }

  let cardsUpperBound = 52
  if(selectedGame == JOKER_POKER_KEY) {
    cardsUpperBound = 53
  }
  for (let i = 0; i < cardsUpperBound; i++) {
      let suit = Math.floor(i/13) // 0, 1, 2, 3
      let top = (suit * (60 + cardMargin)) + 'px'
      let left = (i - suit*13)*(45 + cardMargin) + 'px'

      let imageLeft = selectedCards.indexOf(i) > -1 ? '-45px' : '0px'
      let imgeTop = '-' + i*60 + 'px'

      let style = {
        backgroundPosition: imageLeft + ' ' + imgeTop,
        left: left,
        top: top,
      }
      cards.push(<div style={style} className="card" onClick={onCardClicked.bind(this, i)} key={JSON.stringify(style)}></div>)
  }

  



  let tooltipSlotProps = {
    popper: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -27],
          },
        },
      ],
    },
  }

  let bigCards = []
  let bigCardWidth = 73
  let bigCardPadding = 2
  let bigCardHeight = 97

  for (let j = 0; j < selectedCards.length; j++) {
    let card = selectedCards[j]

    let imageLeft = '0px'
    if(ev != null) {
      imageLeft = holdCards.indexOf(card) == -1 ? '-' + bigCardWidth + 'px' : '0px'
    }
    let imgeTop = '-' + card*bigCardHeight + 'px'

    let style = {
        backgroundPosition: imageLeft + ' ' + imgeTop,
        left: j*(bigCardWidth + bigCardPadding) + 'px',
      }

    let bigCardContainer = <HoldToolTip key={j + card + JSON.stringify(style)} slotProps={tooltipSlotProps} placement="bottom" open={holdCards.indexOf(card) != -1} title="HOLD"><div style={style} className="bigcard" onClick={onBigCardClicked.bind(this, j)}></div></HoldToolTip>
    bigCards.push(bigCardContainer)
  }

  return (
    <div className="App" style={{minWidth: '620px'}}>
      <SimpleResultsDialog open={open} selectedCards={selectedCards} onClose={closeEv} results={allHoldOptions} /> 
      <Box>
        <AppBar position="static">
          <Toolbar variant="dense">
            <img src="/mobbs_logo.png" style={{height: logoHeight}}/>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {SITE_LABEL_BY_KEY[selectedSite]} - {GAME_LABEL_BY_KEY[selectedGame]}
            </Typography>
            <Button color="inherit" onClick={logout}>Logout</Button>
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{flex: '1 1 0px', padding: appPadding, display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '100%'}}>
          <ButtonGroup orientation="horizontal">
            <Tooltip title={BETRIVERS_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={BETRIVERS_KEY} variant={selectedSite == BETRIVERS_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, BETRIVERS_KEY)}>{BETRIVERS_KEY}</Button></Tooltip>
            <Tooltip title={CAESARS_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={CAESARS_KEY} variant={selectedSite == CAESARS_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, CAESARS_KEY)}>{CAESARS_KEY}</Button></Tooltip>
            <Tooltip title={DRAFT_KINGS_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={DRAFT_KINGS_KEY} variant={selectedSite == DRAFT_KINGS_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, DRAFT_KINGS_KEY)}>{DRAFT_KINGS_KEY}</Button></Tooltip>
            <Tooltip title={EAGLE_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={EAGLE_KEY} variant={selectedSite == EAGLE_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, EAGLE_KEY)}>{EAGLE_KEY}</Button></Tooltip>
            <Tooltip title={FANDUEL_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={FANDUEL_KEY} variant={selectedSite == FANDUEL_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, FANDUEL_KEY)}>{FANDUEL_KEY}</Button></Tooltip>
            <Tooltip title={GOLDEN_NUGGET_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={GOLDEN_NUGGET_KEY} variant={selectedSite == GOLDEN_NUGGET_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, GOLDEN_NUGGET_KEY)}>{GOLDEN_NUGGET_KEY}</Button></Tooltip>
            <Tooltip title={GUN_LAKE_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={GUN_LAKE_KEY} variant={selectedSite == GUN_LAKE_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, GUN_LAKE_KEY)}>{GUN_LAKE_KEY}</Button></Tooltip>
            <Tooltip title={SPORTS_ILLUSTRATED_LABEL} placement="top" arrow><Button style={{width: '77px'}} size="small" key={SPORTS_ILLUSTRATED_KEY} variant={selectedSite == SPORTS_ILLUSTRATED_KEY ? 'contained' : 'outlined'} onClick={onSiteClicked.bind(this, SPORTS_ILLUSTRATED_KEY)}>{SPORTS_ILLUSTRATED_KEY}</Button></Tooltip>
          </ButtonGroup>

          <div style={{display: 'block', marginTop: blockMargin + 'px'}}>
            <ButtonGroup orientation="horizontal" >
              <Button key={BONUS_ACTIVATED_KEY} variant={selectedMode == BONUS_ACTIVATED_KEY ? 'contained' : 'outlined'} onClick={onModeClicked.bind(this, BONUS_ACTIVATED_KEY)}>Load</Button>
              <Button key={BONUS_DEACTIVATED_KEY} variant={selectedMode == BONUS_DEACTIVATED_KEY ? 'contained' : 'outlined'} onClick={onModeClicked.bind(this, BONUS_DEACTIVATED_KEY)}>Collect</Button>
            </ButtonGroup>

            <ButtonGroup orientation="horizontal" style={{marginLeft: '40px'}}>
              <Button key={THREE_PLAY_KEY} variant={selectedNumHands == THREE_PLAY_KEY ? 'contained' : 'outlined'} onClick={onNumHandsClicked.bind(this, THREE_PLAY_KEY)}>3-Play</Button>
              <Button key={FIVE_PLAY_KEY} variant={selectedNumHands == FIVE_PLAY_KEY ? 'contained' : 'outlined'} onClick={onNumHandsClicked.bind(this, FIVE_PLAY_KEY)}>5-Play</Button>
              <Button key={TEN_PLAY_KEY} variant={selectedNumHands == TEN_PLAY_KEY ? 'contained' : 'outlined'} onClick={onNumHandsClicked.bind(this, TEN_PLAY_KEY)}>10-Play</Button>
            </ButtonGroup>
          </div>

          <div style={{display: 'block', marginTop: blockMargin + 'px'}}>
            <ButtonGroup orientation="horizontal" style={{margin: 'auto'}}>
              <Button style={{width: '180px'}} size="small" key={JACKS_OR_BETTER_KEY} variant={selectedGame == JACKS_OR_BETTER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, JACKS_OR_BETTER_KEY)}>{JACKS_OR_BETTER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={BONUS_POKER_KEY} variant={selectedGame == BONUS_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, BONUS_POKER_KEY)}>{BONUS_POKER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={BONUS_POKER_DELUXE_KEY} variant={selectedGame == BONUS_POKER_DELUXE_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, BONUS_POKER_DELUXE_KEY)}>{BONUS_POKER_DELUXE_LABEL}</Button>
            </ButtonGroup>
          </div>
          <div style={{display: 'block', marginTop: '5px'}}>
            <ButtonGroup orientation="horizontal" style={{margin: 'auto'}}>
              <Button style={{width: '180px'}} size="small" key={DOUBLE_BONUS_POKER_KEY} variant={selectedGame == DOUBLE_BONUS_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, DOUBLE_BONUS_POKER_KEY)}>{DOUBLE_BONUS_POKER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={DOUBLE_DOUBLE_BONUS_POKER_KEY} variant={selectedGame == DOUBLE_DOUBLE_BONUS_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, DOUBLE_DOUBLE_BONUS_POKER_KEY)}>{DOUBLE_DOUBLE_BONUS_POKER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={TRIPLE_DOUBLE_BONUS_POKER_KEY} variant={selectedGame == TRIPLE_DOUBLE_BONUS_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, TRIPLE_DOUBLE_BONUS_POKER_KEY)}>{TRIPLE_DOUBLE_BONUS_POKER_LABEL}</Button>
            </ButtonGroup>
          </div>
          <div style={{display: 'block', marginTop: '5px'}}>
            <ButtonGroup orientation="horizontal" style={{margin: 'auto'}}>
              <Button style={{width: '180px'}} size="small" key={DEUCES_WILD_POKER_KEY} variant={selectedGame == DEUCES_WILD_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, DEUCES_WILD_POKER_KEY)}>{DEUCES_WILD_POKER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={DEUCES_WILD_BONUS_POKER_KEY} variant={selectedGame == DEUCES_WILD_BONUS_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, DEUCES_WILD_BONUS_POKER_KEY)}>{DEUCES_WILD_BONUS_POKER_LABEL}</Button>
              <Button style={{width: '180px'}} size="small" key={JOKER_POKER_KEY} variant={selectedGame == JOKER_POKER_KEY ? 'contained' : 'outlined'} onClick={onGameClicked.bind(this, JOKER_POKER_KEY)}>{JOKER_POKER_LABEL}</Button>
            </ButtonGroup>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', flex: '1 1 0px'}}>
          

          <div style={{flex: '1 1 0px', display: 'flex', flexDirection: 'column'}}>
            <div style={{margin: 'auto', height: '357px', marginTop: '15px'}}>
              <div style={{margin: 'auto', width: '609px', height: '260px'}}>
                <div style={{margin: 'auto', position: 'relative'}}>
                  {cards}
                </div>
              </div>
              <div style={{margin: 'auto', width: '365px', height: '97px', position: 'relative'}}>
                <div style={{display: 'inline-block', left: '-100px', top: '25px', position: 'absolute', width: '100px', textAlign: 'center', visibility: (selectedCards.reduce((partialSum, a) => partialSum + a, 0) == 270) ? 'hidden' : 'visible'}}>
                  <DeleteIcon style={{color: '#E2281C', verticalAlign: 'middle', marginRight: '15px', width: '40px', height: '40px', cursor: 'pointer'}} onClick={clear}/>
                </div>
                <div style={{margin: 'auto', position: 'relative'}}>
                  {bigCards}
                </div>
                <div onClick={openEV} style={{cursor: 'pointer', display: 'inline-block', right: '-100px', top: '25px', position: 'absolute', width: '100px', textAlign: 'center', visibility: (ev != null)?'visible':'hidden'}}>
                  <div style={{color: '#1565c0'}}><b style={{verticalAlign: 'middle'}}>EV</b><InfoOutlinedIcon style={{color: 'black', verticalAlign: 'middle', marginLeft: '5px', width: '15px', height: '15px', cursor: 'pointer'}}/></div>
                  <div>{ev}</div>
                </div>
              </div>
              <Button variant='contained' onClick={analyze} disabled={selectedCards.indexOf(BLANK_CARD) != -1} style={{width: '365px', marginTop: '25px'}}>Analyze</Button>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );  
}


function SimpleResultsDialog(props) {
  const { onClose, selectedCards, results, open } = props;

  let resultsItems = []
  for (let i = 0; i < Math.min(results.length, 20); i++) {

    let cards = []
    let cardWidth = 45
    let cardPadding = 2
    let cardHeight = 60

    let holdCards = results[i]["holds"]

    for (let j = 0; j < selectedCards.length; j++) {
      let card = selectedCards[j]

      let imageLeft = holdCards.indexOf(card) == -1 ? '-' + cardWidth + 'px' : '0px'
      let imgeTop = '-' + card*cardHeight + 'px'
      let opacity = holdCards.indexOf(card) == -1 ? '.5' : '1'

      let style = {
          backgroundPosition: imageLeft + ' ' + imgeTop,
          left: j*(cardWidth + cardPadding) + 'px',
          opacity: opacity,
        }

      let bigCardContainer = <div style={style} className="card" key={j}></div>
      cards.push(bigCardContainer)
    }

    let ev = <div style={{display: 'inline-block', right: '0px', top: '5px', position: 'absolute', width: '100px', textAlign: 'center'}}>
            <div style={{color: '#1565c0'}}><b>EV</b></div>
            <div>{results[i]["ev"]}</div>
          </div>

    let item = <div style={{margin: 'auto', width: '340px', height: '80px', position: 'relative'}}>
                  <div style={{margin: 'auto', position: 'relative'}} key={i}>
                    {cards}
                    {ev}
                  </div>
                </div>

    resultsItems.push(item)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <div style={{scroll: 'auto', padding: '30px', maxHeight: '300px'}}>
        {resultsItems}
      </div>
    </Dialog>
  );
}

export default App;
