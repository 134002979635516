import React, { useState, useContext } from 'react';

import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import firebase from 'firebase/compat/app';
import "firebase/compat/database";

import logEvent from './KeenHelper'

function AccessDenied({user, logout}) {

	const [accessRequested, setAccessRequested] = useState(false);

	function requestAccess() {		
		var newRequestKey = firebase.database().ref().child('requests').child(user.uid).push().key;

		var updates = {};
		updates['/requests/' + user.uid + '/' + newRequestKey] = firebase.database.ServerValue.TIMESTAMP;
		firebase.database().ref().update(updates);

		setAccessRequested(true)
		logEvent('accessRequested', {email: user.email, displayName: user.displayName, uid: user.uid})
	}

	let contents = <div style={{margin: 'auto'}}>
				<div style={{fontSize: '24px'}}>Access Denied.</div>
				<div style={{fontSize: '14px', marginTop: '10px'}}>To see this page, request access from the owner.</div>
				<Button style={{width: '180px', marginTop: '20px'}} size="large" variant="outlined" onClick={requestAccess}>Request Access</Button>
			</div>
	if(accessRequested) {
		contents = <div style={{margin: 'auto'}}>
			    <img src="/mobbs_logo.png" style={{width: '300px', height: 'auto', display: 'block'}} className="App-logo" />
				<div style={{fontSize: '24px'}}>Access Requested!</div>
				<div style={{fontSize: '14px', marginTop: '10px'}}>Please try back later</div>
			</div>
	}

	return(
	  	<div className="App">
			<Box>
				<AppBar position="static">
					<Toolbar variant="dense">
						<img src="/mobbs_logo.png" style={{height: '50px'}}/>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
						<Button color="inherit" onClick={logout}>Logout</Button>
					</Toolbar>
				</AppBar>
			</Box>
			{contents}
		</div>
	)
}

export default AccessDenied;
