import KeenTracking from 'keen-tracking';

// Configure a client instance
const client = new KeenTracking({
  projectId: '65d227ed40af146c8b5fe094',
  writeKey: 'e010b1a0233dc286f9a6232b411c0a24694a4014f1d5c1cab43a2dcd4f326bf0db394eecfce117fec6e4f73f21f4da0834e2c0b540f5def544881a864a6817bea7c527bb0b7bbf1af6c3a42070e72940b18a9e1cf9865f4b0ed3385e304b50b4'
});

function logEvent(eventType, eventData) {

    eventData.eventType = eventType
    eventData.ip_address = "${keen.ip}"
    eventData.user_agent = "${keen.user_agent}"

    eventData.keen = {
      addons: [
        {
          name: "keen:ip_to_geo",
          input: {
            ip: "ip_address"
          },
          output: "ip_geo_info"
        },
        {
          name: "keen:ua_parser",
          input: {
            ua_string: "user_agent"
          },
          output: "parsed_user_agent"
        }
      ]
    }

  client.recordEvent('Website', eventData);
}



export default logEvent;
