import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SignIn from './SignIn'
import AccessDenied from './AccessDenied'
import CircularProgress from '@mui/material/CircularProgress';
import logEvent from './KeenHelper'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/database";

import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { createTheme, ThemeProvider  } from '@mui/material/styles';

//import {
//  createBrowserRouter,
//  RouterProvider,
//} from "react-router-dom";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDH6ABBiMfog0miwMwTJ1eIGF2Wj4M-kUs",
  authDomain: "ultimatexhandanalyzer.firebaseapp.com",
  databaseURL: "https://ultimatexhandanalyzer-default-rtdb.firebaseio.com",
  projectId: "ultimatexhandanalyzer",
  storageBucket: "ultimatexhandanalyzer.appspot.com",
  messagingSenderId: "165875161403",
  appId: "1:165875161403:web:d9fcfaec1620a8d1e97f1e"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);

let authStateInitialized = false

function AuthWrapper() {

  const [loggedInUser, setLoggedInUser] = useState(null)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [loading, setLoading] = useState(true)

  function onLogoutClicked() {
    auth.signOut()
  }

  useEffect(() => {
    if (!authStateInitialized) {
      authStateInitialized = true
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setLoggedInUser(user)
          setLoading(true)
          firebase.database().ref('/authorized/' + user.uid).once('value').then((snapshot) => {
            logEvent('signIn', {email: user.email, displayName: user.displayName, uid: user.uid, isAuthorized: snapshot.exists()})
            setIsAuthorized(snapshot.exists())
            setLoading(false)
          });
        } else {
          setLoggedInUser(null)
          setIsAuthorized(false)
          setLoading(false)
        }
      });
    }
    return () => {};
  }, []);

  if (loading) {
    <div className="App" style={{backgroundColor: '#1565c0'}}>
      <div style={{margin: 'auto'}}>
        <CircularProgress />
      </div>
    </div>

  } else if (Boolean(loggedInUser) && isAuthorized) {
    return (<App user={loggedInUser} logout={onLogoutClicked} />)
  } else if (Boolean(loggedInUser)){
     return (<AccessDenied user={loggedInUser} logout={onLogoutClicked}/>)
  } else {
    return (<SignIn />)
  }
}

//const router = createBrowserRouter([
//  {
//    path: "/",
//    element: <AuthWrapper />,
//  },
//]);

let theme = createTheme({
  palette: {
    primary: {
      main: '#1565c0',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthWrapper />
    </ThemeProvider>
  </React.StrictMode>
);
